//import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import headerStyles from "../components/header.scss"

const Header = ({ siteTitle }) => (
  <header>
    <div>
      <div className={headerStyles.container_header}>
        <a href="mailto:studio@curious-people.com">studio@curious-people.com</a>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
